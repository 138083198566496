import styled from "styled-components";

export const SecondFooterMain = styled.section`
  color: #fff;
  background: #084793;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;

  @media screen and (max-width: 760px) {
    display: block;
    text-align: center;
  }
`;

export const SecondArea = styled.section`
  background: #084793;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 1rem;

  @media screen and (max-width: 760px) {
    display: block;
    text-align: center;
  }
`;
export const TextArea = styled.div`
  color: #fff;
  padding-top: 0.5rem;
`;

export const Buttons = styled.div`
  min-width: 22rem;
  background: #131823;
  margin: 1rem;
  text-align: center;
  padding: 0.5rem;
  border-radius: 40px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;

  @media screen and (max-width: 760px) {
    margin: 1rem auto;
    width: 30%;
    padding-bottom: 0.5rem;
  }
`;
