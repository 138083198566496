import React from "react";
import {
  FooterBg,
  CopyrightElements,
  CopyrightElements2
} from "./Footer.elements";
import { Container } from "../../globalStyles";
import logo from "../../icons/3bob_logo_white.svg";

function Footer() {
  return (
    <>
      <FooterBg>
        <Container>
          <CopyrightElements>
            <img src={logo} alt="Logo" width="100px" />
          </CopyrightElements>
          <CopyrightElements2>Copyright: 2021, 3 Bob</CopyrightElements2>
        </Container>
      </FooterBg>
    </>
  );
}

export default Footer;
