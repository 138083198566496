import styled from "styled-components";

export const WalletOne = styled.section`
  color: #4c44c4;
  background: #006097;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background-image: url("../gradient.jpg");
  mix-blend-mode: multiply;
  padding-top: 6rem;
  padding-bottom: 6rem;
`;

export const WalletMain = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 10;

  @media screen and (max-width: 760px) {
    display: block;
    width: 100%;
  }
`;

export const WalletMainOne = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border: none;
  border-radius: 24px;
  padding: 1rem;
  text-align: right;
  padding-bottom: 4rem;

  h3 {
    font-size: 1.5rem;
    text-align: center;
  }

  img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 760px) {
    border-radius: 16px;
  }
`;

export const WalletMainTwo = styled.div`
  width: 100%;
  padding: 2.5rem;
  background-color: #131823;
  border: 1px solid #006097;
  border-radius: 30px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;

  h4 {
    color: #4c44c4;
  }
  @media screen and (max-width: 760px) {
    border-radius: 20px;
    margin-top: 2rem;
  }
`;

export const FromInputOne = styled.div`
  width: 100%;
  padding: 1.5rem;
  background-color: #fff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 24rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  @media screen and (max-width: 760px) {
    border-radius: 22px;
    padding: 1rem;
    width: 100%;
  }
`;
export const FromInputTwo = styled.div`
  width: 100%;
  padding: 1.5rem;
  background-color: #fff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 24rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  @media screen and (max-width: 760px) {
    border-radius: 22px;
    padding: 1rem;
    width: 100%;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  background: #084793;
  margin-top: 1.5rem;
  text-align: center;
  padding: 1rem;
  border-radius: 40px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 760px) {
    margin: 2rem auto;
    width: 100%;
  }
`;

export const Modal = styled.div`
  @media screen and (max-width: 760px) {
  }
`;
