import React from "react";
import { InfoSection, Wallet, Charts, Trading } from "../../components";

function Home() {
  return (
    <>
      <InfoSection />
      {/* <Divider /> */}
      <Wallet />
      <Charts />
      <Trading />
    </>
  );
}

export default Home;
