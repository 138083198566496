import React from "react";
import AOS from "aos";
import {
  SecondFooterMain,
  SecondArea,
  TextArea,
  Buttons
} from "./SecondFooter.elements";
import { Container } from "../../globalStyles";

AOS.init();

function SecondFooter() {
  return (
    <>
      <SecondFooterMain data-aos="fade-zoom-in">
        <Container>
          <SecondArea>
            <Buttons>
              <i class="bi bi-cursor-fill"></i> TAP HERE TO ADD TO HOME SCREEN
            </Buttons>

            <TextArea>
              <i class="bi bi-x-lg"></i>
            </TextArea>
          </SecondArea>
        </Container>
      </SecondFooterMain>
    </>
  );
}

export default SecondFooter;
