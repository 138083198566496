import styled from "styled-components";

export const ChartsNew = styled.section`
  background: #131823;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 4rem;

  @media screen and (max-width: 760px) {
    display: block;
    text-align: center;
  }
`;

export const MainsCharts = styled.div`
  border: 0.25px solid #006097;
  border-radius: 0.5rem;
  padding: 0.75rem;
`;

export const WalletMainTwo = styled.div`
  width: 100%;
  padding: 2.5rem;
  background-color: #131823;
  border: 1px solid #006097;
  border-radius: 30px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;

  h4 {
    color: #4c44c4;
  }
  @media screen and (max-width: 760px) {
    border-radius: 20px;
    margin-top: 2rem;
  }
`;

export const FromInputOne = styled.div`
  width: 100%;
  padding: 1.5rem;
  background-color: #fff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 24rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  @media screen and (max-width: 760px) {
    border-radius: 22px;
    padding: 1rem;
    width: 100%;
  }
`;
export const FromInputTwo = styled.div`
  width: 100%;
  padding: 1.5rem;
  background-color: #fff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 24rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  @media screen and (max-width: 760px) {
    border-radius: 22px;
    padding: 1rem;
    width: 100%;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  background: #084793;
  margin-top: 1.5rem;
  text-align: center;
  padding: 1rem;
  border-radius: 40px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 760px) {
    margin: 2rem auto;
    width: 100%;
  }
`;
