import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: #131823; */
  color: #7e96b8;
  font-family: "Quicksand", sans-serif;
}
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const ButtonsMain = styled.div`
  width: 100%;
  background: #084793;
  margin: 1rem 0;
  text-align: center;
  padding: 1rem;
  border-radius: 40px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;

  @media screen and (max-width: 760px) {
    margin: 2rem auto;
    width: 100%;
  }
`;

export default GlobalStyle;
