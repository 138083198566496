import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../icons/3bob_logo_white.svg";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks
} from "./Navbar.elements";

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <img src={logo} alt="Logo" width="100px" />
          </NavLogo>
          <MobileIcon onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </MobileIcon>
          <NavMenu onClick={handleClick} click={click}>
            <NavItem>
              <NavLinks to="/services">
                <i class="bi bi-chat-left-text"> CHAT</i>
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/services">
                <i class="bi bi-phone-vibrate-fill"> CONNECT MOBILE</i>
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default Navbar;
