import React from "react";
import {
  InfoSec,
  HeroSection,
  TextSection,
  ImageSection
} from "./InfoSection.elements";
import { Container } from "../../globalStyles";
import crypto from "../../icons/woman.png";

const AddTripButton = (props) => {
  return <button onClick={props.addTrip}>Add a trip</button>
}

function InfoSection() {
  return (
    <>
      <InfoSec>
        <Container>
          <HeroSection>
            <TextSection className="ndikni">
              <h1>
                Got 3 bob,
                <br />
                Get Bitcoin
              </h1>
              <i
                className="bi bi-arrow-down-circle-fill animate__animated animate__bounce animate__infinite	infinite"
                id="#service"
              ></i>
            </TextSection>
            <ImageSection className="text-right pichni flex-sm-row-reverse">
              <img src={crypto} alt="Logo" width="100%" />
            </ImageSection>
          </HeroSection>
        </Container>
      </InfoSec>
    </>
  );
}

export default InfoSection;
