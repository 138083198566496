import styled from "styled-components";

export const MainTrading = styled.section`
  color: #fff;

  background: #131823;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: left;
  text-align: left;
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media screen and (max-width: 760px) {
    display: block;
    text-align: left;
    padding: 1rem;
  }
`;
