import styled from "styled-components";

export const InfoSec = styled.section`
  color: #fff;

  background: #131823;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url("../../main.jpg");

  @media screen and (max-width: 760px) {
    display: block;
    text-align: center;
  }
`;

export const HeroSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    text-align: center;
    flex-direction: column-reverse;
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-content: center;
  justify-content: center;
  width: 100%;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  color: #fff;

  h1 {
    font-size: 5rem;
    padding-bottom: 1rem;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
  }

  p {
    font-size: 1.25rem;
  }

  i {
    font-size: 1.25rem;
  }

  @media screen and (max-width: 960px) {
    padding-top: 2rem;
    display: block;

    h1 {
      font-size: 3rem;
      text-align: center;
      padding-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      text-align: center;
    }
  }
`;

export const ImageSection = styled.div`
  display: flex;
  justify-self: flex-end;
  padding: 2rem 0rem 4rem 4rem;
  width: 85%;

  @media screen and (max-width: 760px) {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 3rem 0rem 2rem 2rem;
  }
`;

export const Buttons = styled.div`
  max-width: 18rem;
  background: #084793;
  margin-top: 1.5rem;
  text-align: center;
  padding: 1rem;
  border-radius: 40px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;

  @media screen and (max-width: 760px) {
    margin: 2rem auto;
    width: 100%;
  }
`;
