import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import { Container } from "../../globalStyles";
import { MainTrading } from "./Trading.elements";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

export default class PersonList extends React.Component {
  state = {
    persons: []
  };

  componentDidMount() {
    axios
      .get(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=USD&order=market_cap_desc&per_page=10&page=1&sparkline=false`
      )
      .then(res => {
        const persons = res.data;
        this.setState({ persons });
      });
  }

  render() {
    return (
      <>
        <MainTrading>
          <Container>
            <Table>
              <Thead>
                <Tr>
                  <Th>Coin</Th>
                  <Th>Price</Th>
                  <Th>Market Cap</Th>
                  <Th>Total Volume</Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.state.persons.map(person => (
                  <Tr>
                    <Td>
                      <span>
                        <img
                          src={person.image}
                          className="icons"
                          alt="dollas"
                        />
                      </span>
                      <b>{person.name}</b>
                    </Td>

                    <Td>{person.current_price}</Td>

                    <Td>{person.market_cap}</Td>

                    <Td>{person.total_volume}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Container>
        </MainTrading>
      </>
    );
  }
}
