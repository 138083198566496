import React from "react";
import {
  WalletOne,
  WalletMain,
  WalletMainOne,
  WalletMainTwo,
  Buttons
} from "./Wallet.elements";
import { Container, ButtonsMain } from "../../globalStyles";
import logo from "../../icons/mobile.svg";

function Wallet() {
  return (
    <>
      <WalletOne id="box-1">
        <Container>
          <WalletMain>
            <WalletMainOne>
              <img src={logo} alt="erick" width="50%" />
              <h3 className="text-center">
                Buy, Trade, Invest in your Favourite Crypto Assets for 3bob or
                less. No Internet? No Problem. Trade Now. Dial *371#
              </h3>

              <Buttons>
                <i class="bi bi-phone-vibrate-fill"> CONNECT MOBILE</i>
              </Buttons>
            </WalletMainOne>
            <WalletMainTwo>
              Buy Crypto for as low as 3 BOB through MPESA
              <ButtonsMain>
                <i class="bi bi-wallet2"> CHECK BALANCE</i>
              </ButtonsMain>
              <form>
                <label className="bigger">
                  <input
                    type="text"
                    name="name"
                    className="big"
                    placeholder="ETH"
                  />
                </label>
              </form>
              <i class="bi bi-arrow-down-up"></i>
              <form>
                <label className="bigger">
                  <input
                    type="text"
                    name="name"
                    className="big"
                    placeholder="BTC"
                  />
                </label>
              </form>
              <ButtonsMain>
                <i class="bi bi-phone-vibrate-fill"> CONNECT MOBILE</i>
              </ButtonsMain>
            </WalletMainTwo>
          </WalletMain>
        </Container>
      </WalletOne>
    </>
  );
}

export default Wallet;
