import styled from "styled-components";

export const FooterBg = styled.section`
  color: red;
  background-color: #131823;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem 2rem;
`;

export const CopyrightElements = styled.div``;

export const CopyrightElements2 = styled.div`
  margin-top: 1rem;
`;
